import React, { Fragment, lazy, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useState } from '@upstatement/react-hooks';
import { Image, Link, RichText, SEO, Suspense } from '@src/components';
import { className, formatDate, SanityLink } from '@src/utils';
import styles from './game.module.scss';

const Video = lazy(() => import('@src/components/Video'));
const Slideshow = lazy(() => import('@src/components/Slideshow'));

const Game = ({
  data: {
    game: {
      content,
      gameplay,
      gameplayCaption,
      gameplayLabelOverride,
      links,
      role,
      screenshots,
      screenshotCaptions,
      screenshotsLabelOverride,
      seo,
      tech,
      timeline,
      title,
    },
  },
}) => {
  const [slideshowLoaded, setSlideshowLoaded] = useState(false);

  const slides = useMemo(() =>
    screenshots.map(({ image }, i) => {
      const caption = (screenshotCaptions[i] && screenshotCaptions[i].caption) || [];
      return {
        image,
        caption,
      };
    }),
  );

  const dates = useMemo(() => {
    const from = formatDate(timeline.from);
    const to = formatDate(timeline.to);
    if (from === to) {
      return from;
    }
    return `${from} – ${to}`;
  }, [timeline]);

  const onSlideshowInit = () => {
    if (!slideshowLoaded) {
      setTimeout(() => {
        setSlideshowLoaded(true);
      }, 100);
    }
  };

  return (
    <Fragment>
      <SEO seo={seo} title={title} />
      <h1 className={styles.title}>{title}</h1>
      <ul className={styles.meta}>
        {role && (
          <li {...className(styles.metaItem, tech && tech.length && styles.metaItemSplit)}>
            <h2 className={styles.label}>Role</h2>
            <p>{role}</p>
          </li>
        )}
        {tech && !!tech.length && (
          <li {...className(styles.metaItem, role && styles.metaItemSplit)}>
            <h2 className={styles.label}>Tech</h2>
            <ul className={styles.techList}>
              {tech.map((name, i) => (
                <li key={i} className={styles.techItem}>
                  {name}
                  {i < tech.length - 1 && <span>, </span>}
                </li>
              ))}
            </ul>
          </li>
        )}
        <li className={styles.metaItem}>
          <h2 className={styles.label}>Dates</h2>
          <p>{dates}</p>
        </li>
        {links && !!links.length && (
          <li className={styles.metaItem}>
            <h2 className={styles.label}>Links</h2>
            <ul>
              {links.map(sanityLink => {
                const link = new SanityLink(sanityLink);
                return (
                  <li key={link.key} className={styles.linkItem}>
                    <Link className={styles.link} to={link.to}>
                      {link.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        )}
      </ul>
      <section>
        <h2 className={styles.hidden} aria-hidden="true">
          Content
        </h2>
        <RichText className={styles.content} content={content} />
      </section>
      {gameplay && (
        <Suspense>
          <section className={styles.gameplay}>
            <h2 {...className(styles.label, styles.labelSection)}>
              {gameplayLabelOverride || 'Gameplay'}
            </h2>
            <Video className={styles.gameplayVideo} src={gameplay} />
            <RichText className={styles.gameplayCaption} content={gameplayCaption} />
          </section>
        </Suspense>
      )}
      {!!slides.length && (
        <section className={styles.screenshots}>
          <h2 {...className(styles.label, styles.labelSection)}>
            {screenshotsLabelOverride || 'Screenshots'}
          </h2>
          {slides.length === 1 ? (
            <div className={styles.shotSingle}>
              <Image className={styles.shotMedia} value={slides[0].image} />
              <div className={styles.shotCaption}>
                <RichText content={slides[0].caption} />
              </div>
            </div>
          ) : (
            <Suspense>
              <div {...className(styles.slideshow, slideshowLoaded && styles.loaded)}>
                <Slideshow slides={slides} onInit={onSlideshowInit} />
              </div>
            </Suspense>
          )}
        </section>
      )}
    </Fragment>
  );
};

Game.propTypes = {
  data: PropTypes.shape({
    game: PropTypes.object.isRequired,
  }).isRequired,
};

export default Game;

export const query = graphql`
  query GameQuery($slug: String!) {
    game: sanityGame(slug: { current: { eq: $slug } }) {
      title
      role
      tech
      timeline {
        from
        to
      }
      links {
        ...SanityLink
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      gameplayLabelOverride
      gameplay {
        ...SanityVideo
      }
      gameplayCaption: _rawGameplayCaption(resolveReferences: { maxDepth: 10 })
      screenshotsLabelOverride
      screenshotCaptions: _rawScreenshots(resolveReferences: { maxDepth: 10 })
      screenshots {
        image {
          altText
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      seo {
        ...SanitySeo
      }
    }
  }
`;
